import { memo, useEffect, useState } from 'react'
import { Checkbox, SegmentedControl } from '@mantine/core'
import { store } from '~/pages/heineken_template/_private/store'
import { retail_oi } from '~/trades/indicators/futuresai/futures_chips/retail_oi'
import { useSnapshot } from 'valtio'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { css } from '@emotion/react'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import { scrollbar2Css } from '~/css/scrollbarCss'

import { foreigner_twse } from '~/trades/indicators/futuresai/futures_chips/foreigner_twse'
import { dealer_twse } from '~/trades/indicators/futuresai/futures_chips/dealer_twse'
import { trust_twse } from '~/trades/indicators/futuresai/futures_chips/trust_twse'
import { foreigner_tpex } from '~/trades/indicators/futuresai/futures_chips/foreigner_tpex'
import { trust_tpex } from '~/trades/indicators/futuresai/futures_chips/trust_tpex'
import { dealer_tpex } from '~/trades/indicators/futuresai/futures_chips/dealer_tpex'
import { foreigner_txf } from '~/trades/indicators/futuresai/futures_chips/foreigner_txf'
import { trust_txf } from '~/trades/indicators/futuresai/futures_chips/trust_txf'
import { dealer_txf } from '~/trades/indicators/futuresai/futures_chips/dealer_txf'
import { foreigner_opt } from '~/trades/indicators/futuresai/futures_chips/foreigner_opt'
import { dealer_opt } from '~/trades/indicators/futuresai/futures_chips/dealer_opt'
import { large_trader_futures_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top5'
import { large_trader_futures_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_last5'
import { large_trader_futures_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top10'
import { large_trader_options_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top5'
import { large_trader_options_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top10'
import { large_trader_options_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_last5'

/** 再增加指標數量時 自動調整 panesRatio的主圖高度 避免指標被壓縮到媽媽認不出來 */
const autoSetPanesRatio = () => {
  const length = store.charting.indicators.length - 1
  const hightValue = 100 - (4 * length + 20)

  return hightValue
}

const handleChange = (indicators: ChartTypes.Indicator[]) => {
  store.charting.toggleIndicators(indicators)
  store.charting.isIndicatorsIncludes(indicators)
  store.charting.panesRatio = autoSetPanesRatio()
  store.charting.updateFromState()
}

/** 只能放單一的指標 不用給予`[indicators]` */
const IndicatorsCheckBox = memo<
  ReactProps<{ indicators: ChartTypes.Indicator[]; disable?: boolean }>
>(function IndicatorsCheckBox(props) {
  const isDisable = props.disable ?? false

  return (
    <classes.container>
      <Checkbox
        radius='xl'
        label={props.children}
        disabled={isDisable}
        checked={store.charting.isIndicatorsIncludes(props.indicators) ? true : false}
        css={css`
          .mantine-Checkbox-label {
            cursor: pointer;
            font-size: 14px;
          }
        `}
        onClick={event => {
          handleChange(props.indicators)
        }}
      />
    </classes.container>
  )
})

/** pure component 的 checkbox css */
const classes = {
  container: styled.div`
    ${fill_horizontal_all_center};
    width: auto;
    height: 30px;
    border-radius: 10px;
    padding: 0px 16px;
    background-color: #f8f8f8;
    box-shadow: 0px 1px 2px #aaaaaaaa;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: #ffffff;
    }
    flex-shrink: 0;
  `,
}

export const ChipsDashboard = memo<ReactProps>(function ChipsDashboard() {
  //useSnapshot(store.charting).indicators // sub change

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        padding: 6px;
        box-shadow: 0 0 6px 2px #cccccc;
        border-radius: 8px;
      `}
    >
      <styleds.sidebarTitle>籌碼指標組合</styleds.sidebarTitle>

      <styleds.checkboxContent>
        <styleds.optionTitle>上市</styleds.optionTitle>
        <IndicatorsCheckBox indicators={[foreigner_twse]}>外資-上市買賣超</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[trust_twse]}>投信-上市買賣超</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[dealer_twse]}>自營-上市買賣超</IndicatorsCheckBox>

        <styleds.optionTitle>上櫃</styleds.optionTitle>
        <IndicatorsCheckBox indicators={[foreigner_tpex]}>外資-上櫃買賣超</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[trust_tpex]}>投信-上櫃買賣超</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[dealer_tpex]}>自營-上櫃買賣超</IndicatorsCheckBox>

        <styleds.optionTitle>台指期</styleds.optionTitle>
        <IndicatorsCheckBox indicators={[foreigner_txf]}>外資-台指期留倉</IndicatorsCheckBox>
        <IndicatorsCheckBox
          indicators={[trust_txf]}
          disable={true}
        >
          投信-台指期留倉
        </IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[dealer_txf]}>自營-台指期留倉</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_futures_top5]}>
          前五大-台指期留倉
        </IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_futures_top10]}>
          前十大-台指期留倉
        </IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_futures_last5]}>
          後五大-台指期留倉
        </IndicatorsCheckBox>

        <styleds.optionTitle>選擇權</styleds.optionTitle>
        <IndicatorsCheckBox indicators={[foreigner_opt]}>外資-選擇權留倉</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[dealer_opt]}>自營-選擇權留倉</IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_options_top5]}>
          前五大-台指期留倉
        </IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_options_top10]}>
          前十大-台指期留倉
        </IndicatorsCheckBox>
        <IndicatorsCheckBox indicators={[large_trader_options_last5]}>
          後五大-台指期留倉
        </IndicatorsCheckBox>
        <styleds.optionTitle>小台指</styleds.optionTitle>
        <IndicatorsCheckBox indicators={[retail_oi]}>散戶-小台指期留倉</IndicatorsCheckBox>
      </styleds.checkboxContent>
    </div>
  )
})

const styleds = {
  sidebarTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 40px;
    background-color: #679ad1;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
  `,
  optionTitle: styled.div`
    width: 100%;
    height: 28px;
    flex-shrink: 0;
    border-bottom: 1px solid #cccccc;
    line-height: 30px;
  `,
  checkboxContent: styled.div`
    ${scrollbar2Css};
    align-items: start;
    gap: 8px;
    padding: 8px;
  `,
}
