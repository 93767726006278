import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { bs0ForTVChart2 } from '~/trades/indicators/futuresai/bs0'
import { bs1ForTVChart2 } from '~/trades/indicators/futuresai/bs1'
import { opofForTVChart2 } from '~/trades/indicators/futuresai/opof'
import { tiForTVChart2 } from '~/trades/indicators/futuresai/ti'

//
import { foreigner_txf } from '~/trades/indicators/futuresai/futures_chips/foreigner_txf'
import { foreigner_mxf } from '~/trades/indicators/futuresai/futures_chips/foreigner_mxf'
import { foreigner_opt } from '~/trades/indicators/futuresai/futures_chips/foreigner_opt'
import { foreigner_twse } from '~/trades/indicators/futuresai/futures_chips/foreigner_twse'
import { foreigner_tpex } from '~/trades/indicators/futuresai/futures_chips/foreigner_tpex'
import { dealer_twse } from '~/trades/indicators/futuresai/futures_chips/dealer_twse'
import { dealer_tpex } from '~/trades/indicators/futuresai/futures_chips/dealer_tpex'
import { dealer_txf } from '~/trades/indicators/futuresai/futures_chips/dealer_txf'
import { dealer_mxf } from '~/trades/indicators/futuresai/futures_chips/dealer_mxf'
import { dealer_opt } from '~/trades/indicators/futuresai/futures_chips/dealer_opt'
import { trust_twse } from '~/trades/indicators/futuresai/futures_chips/trust_twse'
import { trust_tpex } from '~/trades/indicators/futuresai/futures_chips/trust_tpex'
import { retail_oi } from '~/trades/indicators/futuresai/futures_chips/retail_oi'
import { large_trader_futures_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top5'
import { large_trader_futures_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_top10'
import { large_trader_futures_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_futures_last5'
import { large_trader_options_top5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top5'
import { large_trader_options_top10 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_top10'
import { large_trader_options_last5 } from '~/trades/indicators/futuresai/futures_chips/large_trader_options_last5'
import { settlement_line } from '~/trades/indicators/futuresai/futures_chips/settlement_line'
import { trust_txf } from '~/trades/indicators/futuresai/futures_chips/trust_txf'

const defaultIndicators = [bs1ForTVChart2, bs0ForTVChart2, tiForTVChart2, opofForTVChart2]

const chipsIndicatorsPreloaded = [
  settlement_line,
  foreigner_txf,
  foreigner_twse,
  foreigner_mxf,
  foreigner_tpex,
  foreigner_opt,
  dealer_twse,
  dealer_tpex,
  dealer_txf,
  dealer_mxf,
  dealer_opt,
  trust_tpex,
  trust_twse,
  trust_txf,
  retail_oi,
  large_trader_futures_top5,
  large_trader_futures_top10,
  large_trader_futures_last5,
  large_trader_options_top5,
  large_trader_options_top10,
  large_trader_options_last5,
]

const chipsIndicators = [
  settlement_line,
  foreigner_twse,
  foreigner_tpex,
  foreigner_txf,
  foreigner_opt,
  retail_oi,
]

export const stanli_strategyGroup = {
  main: [
    {
      displayName: '預設',
      indicators: defaultIndicators,
      interval: '5',
      panesRatio: 60,
      symbol: 'TX-1',
    },
  ] as ChartTypes.StrategyConfig[],
  chips: [
    {
      displayName: '籌碼預設',
      indicators: chipsIndicators,
      interval: '5',
      panesRatio: 60,
      symbol: 'TX-1',
    },
  ] as ChartTypes.StrategyConfig[],
}

export const stanli_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...chipsIndicatorsPreloaded,
    ...defaultIndicators,
  ]
  store.charting.initStrategy({
    configs: [...stanli_strategyGroup.main, ...stanli_strategyGroup.chips],
  })
}

// export const stanli_initStrategies_chips = () => {
// store.charting.indicatorsPreloaded = [
//   ...store.charting.indicatorsPreloaded,
//   ...chipsIndicatorsPreloaded,
// ]
// store.charting.initStrategy({
//   configs: [...stanli_strategyGroup.chips],
// })
// store.charting.changeSymbol('TX-1')
// }
